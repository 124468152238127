/**
 * コモンクラス
 * @constructor
 */
class ShopSlide {
    constructor() {

    }

    init() {
        let slideshow = document.querySelector(".slideshow");
        let slides = document.querySelectorAll(".slideshow .slide");
        let slidesArr = Array.prototype.slice.call(slides);
        let bars = document.querySelectorAll(".bars .bar");
        let dots = document.querySelectorAll(".nav-dots .dot a");
        let dotsArr = Array.prototype.slice.call(dots);
        let easeInOutQuart = "cubic-bezier(0.77, 0, 0.175, 1)";
        let easeOutCubic = "cubic-bezier(0.215, 0.61, 0.355, 1)";
        let staggeredSlideIn = (element, delay) => {
            return element.animate([
                { transform: "scaleY(0)", transformOrigin: "top" },
                { transform: "scaleY(1)", transformOrigin: "top" }
            ], {
                duration: 800,
                easing: easeInOutQuart,
                fill: "forwards",
                delay: 80 * delay
            });
        };
        let staggeredSlideOut = (element, delay) => {
            return element.animate([
                { transform: "scaleY(1)", transformOrigin: "top" },
                { transformOrigin: "bottom", offset: 0.001 },
                { transform: "scaleY(0)", transformOrigin: "bottom" }
            ], {
                duration: 800,
                easing: easeInOutQuart,
                fill: "forwards",
                delay: 80 * delay
            });
        };
        let pageTransition = (activeIndex) => {
            console.log(3);
            Promise.all(Array.from(bars).map((bar, i) => staggeredSlideIn(bar, i).finished)).then( function() {
                console.log(2);
                setActiveIndex(activeIndex);
                bars.forEach(function (bar, i) {
                    staggeredSlideOut(bar, i);
                });
            });
        };
        let setActiveIndex = (activeIndex) => {
            console.log(1);
            dotsArr.forEach(function(dot){
                dot.classList.remove("active");
                dots[activeIndex].classList.add("active");
                slideshow.style.setProperty("--active-index", `${activeIndex}`);
            });
            slidesArr.forEach(function(slide) {
                slide.style.zIndex = '-1';
                slidesArr[activeIndex].style.zIndex = '0';
            });
        };
        // dots
        dots[0].classList.add("active");
        dotsArr.forEach(function(dot, activeIndex){
            dot.addEventListener("click", function () {
                let currentActiveIndex = slideshow.style.getPropertyValue("--active-index");
                if (Number(currentActiveIndex) !== activeIndex) {
                    pageTransition(activeIndex);
                }
            });
        });
        
    }
}

export default ShopSlide;
