/**
 * コモンクラス
 * @constructor
 */
class Text {
    constructor() {

    }

    init() {
        function textTrim() {
            // テキストをトリミングする要素
            var selector = document.getElementsByClassName('js-textTrim');
           
            // 制限する文字数
            var wordCount = 57;
           
            // 文末に追加したい文字
            var clamp = '…';
           
            for (var i = 0; i < selector.length; i++) {
              // 文字数を超えたら
              if (selector[i].innerText.length > wordCount) {
                var str = selector[i].innerText; // 文字数を取得
                str = str.substr(0, (wordCount - 1)); // 1文字削る
                selector[i].innerText = str + clamp; // 文末にテキストを足す
              }
            }
          }
          textTrim();
    }

}

export default Text;
