/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper';

/**
 * import modules
 */
import BorderLine from './modules/border-line';
import Text from './modules/text';
import Modal from './modules/modal';
import ShopSlide from './modules/shop-slide';
import Accordion from './modules/accordion';
import Header from './modules/header';
import GoogleMap from './modules/googlemap';

/**
 * Mainクラス
 * @constructor
 */
class Main {
    constructor() {
        this.setup();
    }
    /**
     * インスタンス生成時に実行する処理
     */
    setup() {
        document.addEventListener('DOMContentLoaded', () => {
        this.created();

        })
        window.addEventListener('load', () => {
        this.mounted();
        });
    }
  /**
   * ページ読み込み時に実行する処理
   */
    created() {
        picturefill();
        
        const text = new Text();
        text.init();
        
        const modal = new Modal();
        modal.init();
        
        const accordion = new Accordion();
        accordion.init();
        
        const header = new Header();
        header.init();
        
        
        //TOPのみ実行
        if (document.querySelector('main#home')){
            const googlemap = new GoogleMap();
            googlemap.init();
            
            const shopslide = new ShopSlide();
            shopslide.init();
            
            const borderline = new BorderLine();
            borderline.init();
        }
        
        
    }
    
    /**
     * 画像読み込み後に実行する処理
     */
    mounted() {


        this.init_deSVG('.js-desvg');

        this.init_objectFitImages('.object-fit');

        this.init_smoothScroll();
        
        this.voice_swiper();
        
            
        if (document.querySelector('main#home')){
            this.camp_swiper();
            this.staff_swiper();
        }
    }

    camp_swiper(){
        const camp_swiper = new Swiper('.camp-swiper', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: false,
        effect: 'slide',
        speed: 650,
        spaceBetween: 40,
        autoplay: {
            delay: 4500,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            reverseDirection: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            750: {
                centeredSlides: true,
            }
          }
        }); 
    }
    
    
 
    voice_swiper(){
        const voice_swiper = new Swiper('.voice-swiper', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: false,
        effect: 'slide',
        speed: 650,
        autoplay: {
            delay: 4500,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            reverseDirection: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        }); 
    }
    
    staff_swiper(){
        const staff_swiper = new Swiper('.staff-swiper', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        effect: 'slide',
        speed: 650,
        spaceBetween: 30,
        autoplay: {
            delay: 4500,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            reverseDirection: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            750: {
                slidesPerView: '1.5',
                centeredSlides: true,
            }
          }
        }); 
    }

    /**
     * ライブラリ/deSVG
     * インラインsvgをsvgタグに変換
     * https://github.com/benhowdle89/deSVG
     * @param {Element} target ターゲットとなる要素
     */
    init_deSVG(target) {
        deSVG(target, true)
    }

    /**
     * ライブラリ/object-fit-images
     * css object-fit のポリフィル
     * https://github.com/fregante/object-fit-images
     * @param {Element} target ターゲットとなる要素
     */
    init_objectFitImages(target) {
        objectFitImages(target);
    }

    /**
     * ライブラリ/picturefill
     * html pictureタグのポリフィル
     * https://scottjehl.github.io/picturefill/
     */
    init_picturefill() {
        picturefill();
    }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
    init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     * 
     */
        const targetElement = 'a[href*="#"]';
        
        $(window).on("scroll", function() {
            var now = $(window).scrollTop();
            if (now > 200) {
              $('.l-footer__pageTop').fadeIn("slow");
            } else {
              $('.l-footer__pageTop').fadeOut('slow');
            }
        });
    /**
     * オプション
     * @type {object}
     */
    const option = {
        speed: 1000,
        easing: 'easeInOutCubic',
        speedAsDuration: true,
        //header: '.l-header' 固定ヘッダーの場合
        
    }

        new SmoothScroll(targetElement, option);
    }
}

export default new Main();
