/**
 * コモンクラス
 * @constructor
 */
class GoogleMap {
    constructor() {

    }

    init() {
      function googleMap() {
        var latlng = new google.maps.LatLng(35.648677,139.711160);
        var myOptions = {
          zoom: 18,
          center: latlng,
          mapTypeControlOptions: { mapTypeIds: ['style', google.maps.MapTypeId.ROADMAP] }
        };
          var map = new google.maps.Map(document.getElementById('map'), myOptions);
          /*アイコン設定*/
          var icon = new google.maps.MarkerImage('',/*画像url*/
          new google.maps.Size(70,84),/*アイコンサイズ*/
          new google.maps.Point(0,0)/*アイコン位置*/
        );
        var markerOptions = {
          position: latlng,
          map: map,
          icon: icon,
          title: 'KINETORIE',
          animation: google.maps.Animation.DROP
        };
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: 'Hello World!'
          });
        var marker = new google.maps.Marker(markerOptions);
        var styleOptions = [
        {
        "stylers": [
        { "hue": '#d6d3e7' },
          { "gamma": 0.5 },
          { "saturation": -20 }
        ],
          "elementType": "all",
          "featureType": "all"
        }
        ];
          var styledMapOptions = { name: 'KINETORIE' }/*地図右上のタイトル*/
          var sampleType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
          map.mapTypes.set('style', sampleType);
          map.setMapTypeId('style');
        };
          google.maps.event.addDomListener(window, 'load', function() {
          googleMap();
        });
    }

}

export default GoogleMap;
