/**
 * コモンクラス
 * @constructor
 */
class Accordion {
    constructor() {

    }

    init() {
        $('.js-click').on('click', function () {
            $(this).next('.js-answer').slideToggle(500);
            if ($(this).children(".js-accordion").hasClass('is-active')) {
              $(this).children(".js-accordion").removeClass('is-active');
            } else {
              $(this).children(".js-accordion").addClass('is-active');
            }
            return false;
        });
    }

}

export default Accordion;
