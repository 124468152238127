import ScrollMagic from "scrollmagic";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { gsap, Power3 } from "gsap";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
const controller = new ScrollMagic.Controller;

class BorderLine {
    constructor() {
    }

    init() {
          
        //top business アニメーション
        const top_business = new TimelineMax();
        
        
        var tween_line = TweenMax.to(".border-line--01", 0.1, { width: "+=45"});
        var scene1 = new ScrollMagic.Scene({triggerElement: ".p-topReason__itemTtl--01", duration: 500,})
        .setTween(tween_line)
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
        
        var tween_line2 = TweenMax.to(".border-line--02", 0.3, { width: "+=45"});
        var scene2 = new ScrollMagic.Scene({triggerElement: ".p-topReason__item--02", duration: 500})
        .setTween(tween_line2)
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
        
        new ScrollMagic.Scene({ triggerElement: '.p-topReason__itemTtl--01',})
        .setClassToggle(".p-topReason__numItem--01", "active") 
        .setTween(top_business)
        // .addIndicators({name: "top_business"})
        .addTo(controller);
        
        new ScrollMagic.Scene({ triggerElement: '.p-topReason__item--02',})
        .setClassToggle(".p-topReason__numItem--02", "active") 
        .setTween(top_business)
        // .addIndicators({name: "top_business"})
        .addTo(controller);
        
        new ScrollMagic.Scene({ triggerElement: '.p-topReason__item--03',})
        .setClassToggle(".p-topReason__numItem--03", "active") 
        .setTween(top_business)
        // .addIndicators({name: "top_business"})
        .addTo(controller);
        
        if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
        }else{
            new ScrollMagic.Scene({triggerElement: ".p-topReason__right", duration: 1700, offset: 190})
            .setTween(top_business)
            .setPin(".p-topReason__left")
            // .addIndicators()
            .addTo(controller);
        }
    }
};

export default BorderLine;
