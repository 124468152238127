/**
 * コモンクラス
 * @constructor
 */
class Header {
    constructor() {

    }

    init() {

        $( window ).on( 'scroll', function() {
            if ( 500 < $( this ).scrollTop() ) { // 1000px以上スクロールしたら
                $( '.js-global-nav' ).addClass( 'is-fixed' );
            } else {
                $( '.js-global-nav' ).removeClass( 'is-fixed' );
            }
        });
        
        $(".l-headerSp__menu").on( 'click', function () {
            $(this).toggleClass('is-active');
            $('.l-headerSp__logo').toggleClass('is-active');
            $('.l-headerSp__nav').fadeToggle();
        });
        
        $(".l-headerSp__navItem a").on( 'click', function () {
            $('.l-headerSp__menu').removeClass('is-active');
            $('.l-headerSp__logo').removeClass('is-active');
            $('.l-headerSp__nav').fadeOut();
        });
        
        
    }
    
}

export default Header;
