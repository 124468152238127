
/**
 * コモンクラス
 * @constructor
 */
class Text {
    constructor() {

    }

    init() {
        $( '.js-modal' ).each( function() {
            $( this ).on( 'click', function(e) {
                e.preventDefault();
                var target = $( this ).data( 'target' );
                var modal = document.getElementById( target );
                $( modal ).fadeToggle( 100 );
            });
            $('.js-modal-close').on('click',function(){
                $('.js-industry-modal').fadeOut( 100 );
            });
        });
    }

}

export default Text;
